import React from "react";

const PageNotFound = () => {
  return (
    <div>
      {/* This page is used by CloudFront for 404 errors.*/}
      Sorry, the page you are looking for is not found. Please contact support.
    </div>
  );
};

export default PageNotFound;
