"use strict";

const RiskUtils = require("../misc/common_risk_utils");

/**
 * Calculate criticality scale for a risk link
 */
class CommonCriticalityForLink {
  constructor(
    record,
    rmp,
    riskScale,
    highestNonCriticalRiskScale,
    highestRiskScale,
    handleAsSingleLinkRecord,
  ) {
    this.record = record;
    this.rmp = rmp;
    this.riskScale = riskScale;
    this.highestNonCriticalRiskScale = highestNonCriticalRiskScale;
    this.highestRiskScale = highestRiskScale;
    this.useRulesBasedCriticality = rmp.configureByType && rmp.useRulesBasedCriticality;
    this.handleAsSingleLinkRecord = handleAsSingleLinkRecord;
  }

  getScale() {
    const downgradedScaleToPrevious = this._getScaleDowngradedToPrevious();
    if (downgradedScaleToPrevious) {
      this._logResult(downgradedScaleToPrevious);
      return downgradedScaleToPrevious;
    }

    const downgradedScaleToKey = this._getScaleDowngradedToKey();
    if (downgradedScaleToKey) {
      this._logResult(downgradedScaleToKey);
      return downgradedScaleToKey;
    }

    const scaleImpactedByAlwaysCritical = this._getScaleImpactedByAlwaysCritical();
    if (scaleImpactedByAlwaysCritical) {
      this._logResult(scaleImpactedByAlwaysCritical);
      return scaleImpactedByAlwaysCritical;
    }

    const scaleImpactedByObligatoryCQA = this._getScaleImpactedByObligatoryCQA();
    if (scaleImpactedByObligatoryCQA) {
      this._logResult(scaleImpactedByObligatoryCQA);
      return scaleImpactedByObligatoryCQA;
    }

    const scaleDowngradedToPotential = this._getScaleDowngradedToPotential();
    if (scaleDowngradedToPotential) {
      this._logResult(scaleDowngradedToPotential);
      return scaleDowngradedToPotential;
    }

    const scale = { ...this.riskScale };
    scale.rule = RiskUtils.CRITICALITY_RULES.MAXIMUM;

    this._logResult(scale);
    return RiskUtils.cleanScale(scale);
  }

  _getScaleDowngradedToPrevious() {
    if (!this.useRulesBasedCriticality) {
      return;
    }

    // critical linking to non critical quality attribute
    if (
      this._isCritical() &&
      !this._riskLinkIsPotential() &&
      this._targetSupportsRiskAssessment() &&
      ((!this._targetIsPerformanceAttribute() && !this._targetIsCritical()) ||
        !this._targetCriticalityIsAssessed())
    ) {
      let scale = { ...this.riskScale };

      if (this._isAlwaysCritical()) {
        scale = { ...this.highestRiskScale };
      }

      scale.riskLabelBeforeOverwride = scale.riskLabel;
      scale.riskLabel = this.highestNonCriticalRiskScale.riskLabel;
      scale.color = this.highestNonCriticalRiskScale.color;
      scale.critical = this.highestNonCriticalRiskScale.critical;
      scale.rule = RiskUtils.CRITICALITY_RULES.DOWNGRADE_PREVIOUS;
      scale.ruleReason = "Critical link to non-critical " + this._getTargetNameAndRiskLabel();
      return RiskUtils.cleanScale(scale);
    }
  }

  _getScaleDowngradedToKey() {
    if (!this.useRulesBasedCriticality) {
      return;
    }

    if (this._isCritical() && this._targetIsCritical() && this._targetIsPerformanceAttribute()) {
      let scale = { ...this.riskScale };

      if (this._isAlwaysCritical()) {
        scale = { ...this.highestRiskScale };
      }

      scale.critical = true;
      scale.riskLabelBeforeOverwride = scale.riskLabel;
      scale.riskLabel = this.rmp.keyLabel || RiskUtils.CRITICALITY_RULES.DOWNGRADE_KEY;
      scale.rule = RiskUtils.CRITICALITY_RULES.DOWNGRADE_KEY;
      scale.ruleReason = "Critical/Key linked to " + this._getTargetNameAndRiskLabel();
      return RiskUtils.cleanScale(scale);
    }
  }

  _getTargetNameAndRiskLabel() {
    const targetRecord = this._getTargetRecord();
    if (!targetRecord) {
      return "";
    }

    let label = targetRecord.name;
    if (
      targetRecord.riskInfo &&
      targetRecord.riskInfo[RiskUtils.RISK_TYPE_ENUM.CRITICALITY] &&
      targetRecord.riskInfo[RiskUtils.RISK_TYPE_ENUM.CRITICALITY].scaleForRiskLabel
    ) {
      label +=
        " " +
        targetRecord.riskInfo[RiskUtils.RISK_TYPE_ENUM.CRITICALITY].scaleForRiskLabel.riskLabel;
    }

    return label;
  }

  _getScaleImpactedByAlwaysCritical() {
    if (!this._isAlwaysCritical()) {
      return;
    }

    const riskScale = { ...this.riskScale };
    riskScale.riskLabelBeforeOverwride = riskScale.riskLabel;
    riskScale.riskLabel = this.highestRiskScale.riskLabel;
    riskScale.color = this.highestRiskScale.color;
    riskScale.alwaysCritical = true;
    riskScale.critical = true;
    riskScale.rule = RiskUtils.CRITICALITY_RULES.ALWAYS_CRITICAL;
    riskScale.ruleReason = "Impact marked as AlwaysCritical";
    return RiskUtils.cleanScale(riskScale);
  }

  _getScaleImpactedByObligatoryCQA() {
    return false;
  }

  _getScaleDowngradedToPotential() {
    const supportsPotential =
      this.rmp.usePotential && this._isCritical() && this._riskLinkIsPotential();
    if (!supportsPotential) {
      return;
    }

    let riskScale = { ...this.riskScale };

    if (this._isAlwaysCritical()) {
      riskScale = { ...this.highestRiskScale };
    }

    riskScale.riskLabelBeforeOverwride = riskScale.riskLabel;
    riskScale.riskLabel = this.rmp.potentialLabel || RiskUtils.CRITICALITY_RULES.POTENTIAL;
    riskScale.critical = false;
    riskScale.potential = true;
    riskScale.rule = RiskUtils.CRITICALITY_RULES.POTENTIAL;
    riskScale.ruleReason = "Potential uncertainty";
    return RiskUtils.cleanScale(riskScale);
  }

  _targetCriticalityIsAssessed() {
    const record = this._getTargetRecord();
    return (
      this._targetSupportsRiskAssessment() &&
      record &&
      record.riskInfo &&
      !RiskUtils.scaleIsNotAssessed(record.riskInfo[RiskUtils.RISK_TYPE_ENUM.CRITICALITY].scale)
    );
  }

  _targetIsCritical() {
    const record = this._getTargetRecord();
    return (
      record && record.riskInfo && record.riskInfo[RiskUtils.RISK_TYPE_ENUM.CRITICALITY].isCritical
    );
  }

  _targetIsPerformanceAttribute() {
    const record = this._getTargetRecord();
    return record && RiskUtils.isPerformanceAttribute(record.typeCode);
  }

  _targetSupportsRiskAssessment() {
    const record = this._getTargetRecord();
    return record && record.typeCode !== "GA";
  }

  _getTargetRecord() {
    return this.record.targetRecord;
  }

  _riskLinkIsPotential() {
    if (!this._isCritical()) {
      return false;
    }

    if (this._isAlwaysCritical()) {
      return false;
    }

    let uncertaintyRiskScore = RiskUtils.getUncertainty(this.rmp, this.record);
    let uncertaintyScale = RiskUtils.getRiskScale(
      RiskUtils.RISK_TYPE_ENUM.UNCERTAINTY,
      this.rmp,
      uncertaintyRiskScore,
      this.record,
    );

    if (!uncertaintyScale) {
      return false;
    }

    return uncertaintyScale.potential;
  }

  _isCritical() {
    return this.riskScale.critical || this._isAlwaysCritical();
  }

  _isAlwaysCritical() {
    let alwaysCriticalImpact =
      this.rmp.boundaries && this.rmp.boundaries.alwaysCritical
        ? this.rmp.boundaries.alwaysCritical.impact
        : 0;
    let impactRiskScore = RiskUtils.getImpact(this.rmp, this.record);

    let uncertaintyRiskScore = RiskUtils.getUncertainty(this.rmp, this.record);
    let isAlwaysCritical =
      alwaysCriticalImpact &&
      impactRiskScore > 0 &&
      uncertaintyRiskScore > 0 &&
      impactRiskScore >= alwaysCriticalImpact.riskScore;
    return isAlwaysCritical;
  }

  _logResult(scale) {
    const recordId = RiskUtils.getRecordIdForLogging();
    const enableLogging = this.handleAsSingleLinkRecord
      ? this.record.id === recordId
      : this.record.parentRecordId === recordId;
    // const enableLogging = false;
    if (enableLogging) {
      let ruleInfo = "Rule: No overwrites";
      if (scale.rule) {
        ruleInfo = `Rule: ${scale.rule} - Rule Reason: ${scale.ruleReason}`;
      }

      console.log(`${this._getRecordNameForLog()} - ${ruleInfo} - Risk Label: ${scale.riskLabel}`);
    }
  }

  _getRecordNameForLog() {
    if (this.handleAsSingleLinkRecord) {
      return `${this.record.name} (${this.record.id}) - Impact: ${this.record.impact} Uncertainty: ${this.record.uncertainty}`;
    } else {
      let targetName = this.record.targetRecord ? this.record.targetRecord.name : "Unknown";
      let targetId = this.record.targetRecord ? this.record.targetRecord.id : "";
      return `${this.record.parentRecordName} (${this.record.parentRecordId}) to ${targetName} (${targetId}) - Impact: ${this.record.impact} Uncertainty: ${this.record.uncertainty}`;
    }
  }
}

module.exports = {
  CommonCriticalityForLink,
};
