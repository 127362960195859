"use strict";

const { EXPERIMENTS } = require("./common_experiments");
const { UtilityExtension } = require("./common_utility_extension");

let singletonInstance;

class ExperimentManager extends UtilityExtension {
  /**
   * @param commonUtils
   * @returns {ExperimentManager}
   */
  static instance(commonUtils = null) {
    if (!singletonInstance) {
      singletonInstance = new ExperimentManager(commonUtils);
    }
    return singletonInstance;
  }

  /**
   * Retrieves all active experiments for the current environment.
   * @returns {IExperiment[]}
   */
  getAllExperiments(isAPI = false) {
    // noinspection JSValidateTypes
    /**
     * @type {IExperiment[]}
     */
    const experiments = Object.values(EXPERIMENTS);
    const availableExperiments = experiments.filter((experiment) => {
      return !experiment.internal || !this.commonUtils.isEnvironmentPublicToClients();
    });
    if (isAPI) {
      return availableExperiments.filter((experiment) => {
        return experiment.API;
      });
    }
    return availableExperiments;
  }

  getExperimentByKey(key) {
    const experiment = EXPERIMENTS[key];
    if (!experiment) {
      throw new Error(`The experiment ${key} does not exist`);
    }
    return experiment;
  }

  /**
   * Checks whether an experiment is enabled for the specified user
   * @param user {User|UserVersion} an object representing a user of a user version in the system.
   * @param experiment {IExperiment|EXPERIMENTS} The object that describes an experiment, as defined in the {@link exports.EXPERIMENTS} constant.
   * @return {boolean}
   */
  isExperimentEnabledForUser(user, experiment) {
    let object;
    // if we receive a raw user object with the experiments in a string
    if (user.experiments && typeof user.experiments === "string") {
      object = JSON.parse(user.experiments);
    } else if (user.experiments && typeof user.experiments === "object") {
      // if we receive a user with the experiments already parsed
      object = user.experiments;
    } else {
      object = null;
    }
    return this.isExperimentEnabled(object, experiment);
  }

  /**
   * Checks whether an experiment is enabled in the specified experiments object.
   * @param object {*} An object that contains the experiment names as properties and a boolean that indicates whether they are enabled or not.
   * @param experiment {IExperiment|EXPERIMENTS} The object that describes an experiment, as defined in the {@link exports.EXPERIMENTS} constant.
   * @return {boolean}
   */
  isExperimentEnabled(object, experiment) {
    let isEnabled = false;

    if (object && experiment) {
      isEnabled = !!(experiment.key && object[experiment.key]);

      // external experiments can only be enabled in internal environments
      if (isEnabled && experiment.internal) {
        isEnabled = !this.commonUtils.isEnvironmentPublicToClients();
      }
    }

    return isEnabled;
  }
}

module.exports = {
  ExperimentManager,
  EXPERIMENTS,
};
