"use strict";

const CommonUtils = require("./common_utils");

/**
 * Defines some constants for the error categories.
 * @enum {string}
 */
const ERROR_CATEGORY = {
  APPLICATION: "Application",
  DB_ERROR: "DBError",
  RETRY_LATER: "RetryLater",
  UNEXPECTED: undefined,
  VALIDATION: "Validation",
  LICENSE_UPGRADE_REQUIRED: "LicenseUpgradeRequired",
};

const DEFAULT_MESSAGE = "One or more errors occurred.";

/**
 * @typedef ICommonAggregateErrorOptions
 * @property {string} [message] The error message for this error (if not set, it will use the one from the original error object).
 * @property {string} [additionalStackTrace]  Additional information to add to the stack trace.
 * @property {boolean} [showChildrenStackOnly] If true, only shows the stack trace of the child errors (not the one from the current error)
 * @property {ERROR_CATEGORY} [category] A value that identifies the nature of this error.
 */

/**
 * Defines a type of error that contains a list of other errors.
 * It also standardizes other kinds of Aggregate errors that might come from other frameworks.
 */
class CommonAggregateError extends Error {
  /**
   * @param errorOrErrorArray {Error[] | Error} The errors to wrap inside this instance.
   * @param [options] The options for this error
   **/
  constructor(errorOrErrorArray, options = {}) {
    super();

    const { message, category, additionalStackTrace, showChildrenStackOnly } = options;

    const children = [];
    let firstError = null;
    let isArray = Array.isArray(errorOrErrorArray);

    if (isArray) {
      // This was borrowed from https://gist.github.com/justmoon/15511f92e5216fa2624b
      Error.captureStackTrace(this, this.constructor);
      for (let childError of errorOrErrorArray) {
        childError = CommonAggregateError.standardizeError(childError, false);
        children.push(childError);
      }
      firstError = children[0];
      this.name = this.constructor.name;

      // creates an array with the unique messages (if there are repeated messages, includes just one of them)
      const uniqueMessages = [...new Set(children.map((childError) => childError.message))];

      if (
        uniqueMessages.length === 1 &&
        firstError &&
        firstError.message &&
        (!message || message === DEFAULT_MESSAGE || firstError.message.trim() === message.trim())
      ) {
        this.message = firstError.message.trim();
      } else {
        this.message = `${message || "Multiple errors occurred:"}\n${uniqueMessages.map((msg) => `- ${msg}`).join("\n")}`;
      }
      // If we have multiple status codes, we get the highest one (the most critical one)
      const allStatusCodes = children
        .filter((child) => CommonUtils.parseInt(child.statusCode))
        .map((child) => child.statusCode);

      if (allStatusCodes.length > 0) {
        this.statusCode = Math.max(...allStatusCodes);
      }

      this.uuid = CommonUtils.generateUUID();
    } else {
      firstError = CommonAggregateError.standardizeError(errorOrErrorArray, false);
      children[0] = firstError;

      // Copies the properties from the first error
      Object.assign(this, firstError);

      // Captures our own stack trace
      Error.captureStackTrace(this, this.constructor);

      this.stack = this.stack + "\n\n---- ORIGINAL STACK: ----\n\n" + firstError.stack + "\n";
      this.name = firstError.constructor.name;
      this.uuid = firstError.uuid || CommonUtils.generateUUID();
      this.message =
        !message || message === DEFAULT_MESSAGE
          ? firstError.message
          : message + (!message.includes(firstError.message) ? "\n" + firstError.message : "");
      if (firstError.statusCode) {
        this.statusCode = firstError.statusCode;
      }
    }

    // If there's a single error inside, we use its error code (if available, or try to infer it from its name).
    this.code =
      (firstError && (firstError.code || (firstError.name !== "Error" && firstError.name))) ||
      this.constructor.name;

    this.errors = children;

    this.category = category
      ? category
      : firstError && CommonAggregateError.getErrorCategory(firstError);

    this.isValidation = this.category === ERROR_CATEGORY.VALIDATION;
    this.doNotSendStackTrace =
      this.category === ERROR_CATEGORY.APPLICATION ||
      this.category === ERROR_CATEGORY.RETRY_LATER ||
      this.category === ERROR_CATEGORY.VALIDATION;

    if (firstError.sql) {
      this.sql = firstError.sql;
    }

    if (!isArray || !showChildrenStackOnly) {
      this.stack = this.getDetailedErrorStack(this);
    }

    if (additionalStackTrace) {
      this.stack += "\n\n---- ADDITIONAL STACK TRACE: ----\n\n" + additionalStackTrace;
    }
    if (isArray) {
      this.stack +=
        "\n\n---- INNER ERRORS: ----\n\n" +
        children.map(this.getDetailedErrorStack).join("\n---\n");
    }
  }

  getDetailedErrorStack(error, index = null) {
    /**
     * Indents the specified text
     *
     * NOTE:
     * This method may run bound to weird places for some reason, so we are declaring it as a local function.
     * @param text {String} The text to be indented.
     * @return {string} That text, indented.
     */
    function indentAtSameLevel(text) {
      return (text || "")
        .split("\n")
        .map((line) => (line.startsWith("|  ") ? ` ${line}` : `   ${line}`))
        .join("\n");
    }

    let header = index !== null ? `Error ${index}: ` : "";
    header += `${error.message}\n`;
    header += error.model ? ` - Model: ${error.model}\n` : "";
    header += error.name ? ` - Name: ${error.name}\n` : "";
    header += error.code ? ` - Code: ${error.code}\n` : "";
    header += error.category ? ` - Category: ${error.category}\n` : "";
    header += error.uuid ? ` - UUID: ${error.uuid}\n` : "";

    if (
      (!CommonUtils.isProductionOrStaging() && !CommonUtils.isEnvironmentPublicToClients()) ||
      CommonUtils.isEnterpriseStagingEnvironment()
    ) {
      header += error.sql ? ` - SQL: \n${indentAtSameLevel(error.sql)}\n` : "";
    }

    header += `\n - Stack: \n${indentAtSameLevel(
      error.stack
        .split("\n")
        .map((line) => (line.startsWith("|  ") ? line : `|  ${line}`))
        .join("\n"),
    )}`;

    // indents everything one level with a guide vertical line for clarity
    return header
      .split("\n")
      .map((line) => `|  ${line}`)
      .join("\n");
  }

  /**
   * Takes an error object as input and tries to standardize it.
   *
   * In other words, if this is an {@link AggregateError} from Bluebird or the built-in one from node,
   * it will convert into our {@link StandardAggregateError}.
   *
   * Otherwise, it will return the error as is.
   * @param error {Error|AggregateError}
   * @param wrapSimpleErrors {boolean} If true, it will wrap any error (including the ones that don't have child errors)
   * @returns {*}
   */
  static standardizeError(error, wrapSimpleErrors = true) {
    if (error.standardized) {
      return error;
    }
    error.standardized = true;
    // Depending on the promises framework used by the library being called, it may also contain a class
    // called AggregateError, and that class might be slightly different in structure.
    if (error.name === "AggregateError") {
      if (error["length"] && error["forEach"]) {
        for (let itemIndex = 0; itemIndex < error["length"]; itemIndex++) {
          const childErrorArray = [];
          // Bluebird will return an "array-like" object that has the foreach method, but
          // that doesn't work with for...of
          error["forEach"]((item) => {
            if (item) {
              childErrorArray.push(item);
            }
          });
          error = new CommonAggregateError(childErrorArray, {
            message: error.message,
            additionalStackTrace: error.stack,
          });
        }
      } else if (Array.isArray(error.errors)) {
        error = new CommonAggregateError(error.errors, {
          message: error.message,
          additionalStackTrace: error.stack,
        });
      }
    } else if (wrapSimpleErrors && !(error instanceof CommonAggregateError)) {
      error = new CommonAggregateError(error);
    }
    return error;
  }

  /**
   * Attempts to retrieve the error category from an error object.
   * @param error {Error|CommonAggregateError} The error to extract the category from
   * @return {ERROR_CATEGORY|string|*}
   */
  static getErrorCategory(error) {
    if (error.category) {
      return error.category;
    } else if (
      // Sequelize errors for timeouts don't have a code, but have a name
      error.name === "SequelizeConnectionAcquireTimeoutError" ||
      error.name === "SequelizeConnectionError" ||
      // If we wrapped them into a CommonAggregate error and it's the only error, it will
      // have the name into the code.
      error.code === "SequelizeConnectionAcquireTimeoutError" ||
      error.code === "SequelizeConnectionError" ||
      // If we have a non-standard way of aggregating the errors or if we have a CommonAggregateError with
      // more than one error, we try to find if it's a timeout from the stack.
      (error.stack &&
        (error.stack.includes("Lock wait timeout exceeded") ||
          error.stack.includes("try restarting transaction") ||
          error.stack.includes("SequelizeConnectionError") ||
          error.stack.includes("SequelizeConnectionAcquireTimeoutError")))
    ) {
      return ERROR_CATEGORY.RETRY_LATER;
    } else if (
      (error.code && error.code.toString().startsWith("Sequelize")) ||
      (error.name && error.name.toString().startsWith("Sequelize"))
    ) {
      return ERROR_CATEGORY.DB_ERROR;
    } else if (error.isValidation) {
      return ERROR_CATEGORY.VALIDATION;
    } else if (error.doNotSendStackTrace) {
      return ERROR_CATEGORY.APPLICATION;
    } else {
      return ERROR_CATEGORY.UNEXPECTED;
    }
  }
}

module.exports = {
  CommonAggregateError,
  ERROR_CATEGORY,
};
