"use strict";

import React, { Fragment } from "react";

import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

import BaseReactComponent from "@client/base_react_component";
import * as I18NWrapper from "@client/i18n/i18n_wrapper";

import CommonUtils from "../../../server/common/generic/common_utils";

const shouldShowLinkToSystemStatus =
  (!CommonUtils.isProductionOrStaging() && !CommonUtils.isEnvironmentPublicToClients()) ||
  CommonUtils.isEnterpriseStagingEnvironment();

// i18next-extract-mark-ns-start widgets
class FooterBar extends BaseReactComponent {
  render() {
    const { t } = this.props;
    return (
      <div className="footer container">
        <div className="footer-copyright" id="footerCopyright">
          &copy; 2017-{moment().format("YYYY")}
          <a href="https://www.qbdvision.com" rel="noopener noreferrer" target="_blank">
            {" "}
            QbDVision, Inc.
          </a>
        </div>
        <div className="footer-separator">●</div>
        <div className="footer-links">
          <a id="footerPrivacyPolicy" href="/terms/privacyPolicy.html" target="_blank">
            {t("Privacy Policy")}
            <span className="footer-link-icon">
              <FontAwesomeIcon id="footerPrivacyPolicy" icon={faExternalLinkAlt} />
            </span>
          </a>
        </div>
        {shouldShowLinkToSystemStatus ? (
          <Fragment>
            <div className="footer-separator">●</div>
            <div className="footer-links">
              <a id="footerCheckSystem" href="/sysChecks/check.html">
                {t("System Status")}
              </a>
            </div>
          </Fragment>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default I18NWrapper.wrap(FooterBar, "widgets");
// i18next-extract-mark-ns-stop widgets
