"use strict";

import React from "react";

import { Auth } from "aws-amplify";
import Cookies from "js-cookie";

import BaseReactComponent from "@client/base_react_component";
import * as UIUtils from "@client/ui_utils";
import InactivityTimerPopup from "@client/users/inactivityTimer/inactivity_timer_popup";

/**
 * The cookie we set so that other tabs don't timeout.
 * @type {string}
 */
const LAST_ACTIVITY_COOKIE = "LAST_ACTIVITY";
/**
 * The cookie we set if a user chooses to stay instead of logout when asked.
 * @type {string}
 */
const LAST_STAY_PRESSED_COOKIE = "LAST_STAY_PRESSED";

/**
 * This class is responsible for determining if the user has been inactive and when they have, it kicks them out.
 */
export default class InactivityTimer extends BaseReactComponent {
  constructor(props) {
    super(props);

    this.state = {
      showInactivityTimerPopup: false,
    };

    this.secondsToWarning = 120 * 60; // 2 hours
    this.secondsToLogout = 15 * 60; // Another 15 mins after the warning
    this.warningTimerId = null;
    this.logoutTimerId = null;
    this.logoutTimerTimestamp = null;
    this.checkForLogoutTimerId = null;

    // It's important to only do this once
    document.addEventListener("mousemove", this.reinitializeTimer, false);
    document.addEventListener("mousedown", this.reinitializeTimer, false);
    document.addEventListener("keypress", this.reinitializeTimer, false);
    document.addEventListener("touchmove", this.reinitializeTimer, false);
    document.addEventListener("scroll", this.reinitializeTimer, false);

    this.reinitializeTimer();
    this.checkForLogoutTimerId = window.setInterval(this.handleCheckLogout, 1000);
  }

  /**
   * Change the amount of time a user has before they will be warned and/or logged out.
   *
   * @param secondsToWarning The number of seconds until they see a warning.
   * @param secondsToLogout The number of seconds after the warning goes up until they are logged out.
   */
  changeInactivityTime(secondsToWarning, secondsToLogout) {
    this.secondsToWarning = secondsToWarning;
    this.secondsToLogout = secondsToLogout;
    this.reinitializeTimer();
  }

  /**
   * This is called when the user wants to logout.
   */
  cancelLogoutTimer() {
    if (this.checkForLogoutTimerId) {
      window.clearTimeout(this.checkForLogoutTimerId);
      this.checkForLogoutTimerId = null;
    }
  }

  reinitializeTimer() {
    // Clear any pre-existing timeouts
    if (this.warningTimerId) {
      window.clearTimeout(this.warningTimerId);
    }
    Cookies.set(LAST_ACTIVITY_COOKIE, Date.now());

    this.warningTimerId = window.setTimeout(
      this.handleWarningTimeout,
      this.secondsToWarning * 1000,
    );
  }

  handleWarningTimeout() {
    // Look to see if activity has happened on a different tab
    const msSinceLastActivity = this.getMsSinceLastActivity(LAST_ACTIVITY_COOKIE);
    if (msSinceLastActivity >= (this.secondsToLogout + this.secondsToWarning) * 1000) {
      // The latest Chrome seems to freeze the window from processing when it's not active.
      // Uncomment for verbose logging
      // console.log("[Inactivity Timeout] Logging out immediately because we've been frozen for some reason.");
      this.handleLogoutTimeout();
    } else if (msSinceLastActivity >= this.secondsToWarning * 1000) {
      // Uncomment for verbose logging
      // console.log("[Inactivity Timeout] Warning user because no activity has been seen for " + this.secondsToWarning);

      this.setStateSafely({ showInactivityTimerPopup: true });
      this.logoutTimerId = window.setTimeout(this.handleLogoutTimeout, this.secondsToLogout * 1000);
      this.logoutTimerTimestamp = Date.now();
    } else {
      // Uncomment for verbose logging
      // console.log("[Inactivity Timeout] Found activity in another tab.");
      window.clearTimeout(this.warningTimerId);
      const msMoreToWait = this.secondsToWarning * 1000 - msSinceLastActivity;
      this.warningTimerId = window.setTimeout(this.handleWarningTimeout, msMoreToWait);
    }
  }

  getMsSinceLastActivity(lastActivityCookie) {
    const lastActivity = UIUtils.parseInt(Cookies.get(lastActivityCookie));
    return Date.now() - lastActivity;
  }

  handleLogoutTimeout() {
    // Uncomment for verbose logging
    // console.log("[Inactivity Timeout] Logging out because no activity has been seen for " + this.secondsToLogout);
    Cookies.set(UIUtils.LOGOUT_REASON, "Expired");
    this.logout();
  }

  /**
   * Check periodically to see if we've been logged out (or asked to stay open) on a different tab.
   */
  handleCheckLogout() {
    // Uncomment for VERY verbose logging
    // console.log("[Inactivity Timeout] Checking cognito UUID is still here.", UIUtils.getCognitoUUID());
    if (!UIUtils.getCognitoUUID()) {
      this.logout();
    } else if (this.logoutTimerId && this.getMsSinceLastActivity(LAST_STAY_PRESSED_COOKIE) < 1500) {
      // Uncomment for verbose logging
      // console.log("[Inactivity Timeout] Staying open because stay was pressed somewhere else.");
      this.handleStay(false);
    }
  }

  handleStay(shouldSetCookieToWarnOtherTabs = true) {
    // Uncomment for verbose logging
    // console.log("[Inactivity Timeout] Staying open. Setting cookies: " + shouldSetCookieToWarnOtherTabs);
    $(this.inactivityTimerPopup).modal("hide");
    this.setStateSafely({ showInactivityTimerPopup: false });
    if (this.logoutTimerId) {
      window.clearTimeout(this.logoutTimerId);
      this.logoutTimerId = null;
      this.logoutTimerTimestamp = null;
      if (shouldSetCookieToWarnOtherTabs) {
        Cookies.set(LAST_STAY_PRESSED_COOKIE, Date.now());
      }
    }

    // Restart the timer to logout after more inactivity.
    this.reinitializeTimer();
  }

  handleLogout() {
    this.logout();
  }

  /**
   */
  logout() {
    // we cannot use React Router here because this component runs outside of Router context
    UIUtils.clearPreventNavigationForBrowser();

    UIUtils.setLoadingDisabled(false);
    UIUtils.showLoadingImage();
    UIUtils.clearSessionInfoForLogout();
    const logoutReasonCookie = Cookies.get(UIUtils.LOGOUT_REASON);
    let logoutReasonQueryString = "";
    if (logoutReasonCookie) {
      logoutReasonQueryString = "reason=" + logoutReasonCookie;
    }
    Auth.signOut().then(() => {
      // we cannot use React Router here because this component runs outside of Router context
      window.location.href = UIUtils.getSecuredURL(
        "/index.html?" +
          logoutReasonQueryString +
          "&returnTo=" +
          encodeURIComponent(
            UIUtils.getSecuredURL(window.location.href, {
              enforceHostWithinQbDVisionDomain: true,
            }),
          ),
      );
    });
  }

  render() {
    return this.state.showInactivityTimerPopup ? (
      <InactivityTimerPopup
        modalRef={(inactivityTimerPopup) => (this.inactivityTimerPopup = inactivityTimerPopup)}
        secondsToLogout={this.secondsToLogout}
        onStay={this.handleStay}
        onLogout={this.handleLogout}
      />
    ) : null;
  }
}
