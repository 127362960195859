"use strict";

const moment = require("moment");

class Converters {
  /**
   * Function that converts a value to a boolean (it won't use the same logic as the truthy/falsy checks).
   *
   * The values <code>'true'</code>, <code>'1'</code>, <code>1</code> and <code>true</code>
   * will evaluate to <code>true</code>.
   *
   * Anything else will be <code>false</code>.
   * @param value {*} A value to be converted to boolean
   * @return {boolean} A {@link Boolean} with the converted value
   */
  static toBoolean(value) {
    return !!(
      value &&
      (value.toString().toLowerCase() === "true" || value === "1" || value === 1 || value === true)
    );
  }

  /**
   * Function that converts a value to a string, while passing-through null or undefined values.

   * @param value {*} A value to be converted to boolean
   * @return {string} A {@link string} with the converted value
   */
  static toString(value) {
    return typeof value === "undefined" || value === null ? value : String(value);
  }

  /**
   * Function that converts any date object to a UTC date.
   * @param someDate
   * @param format Date format
   * @returns {Date} a UTC date
   */
  static toUTCDateFromDate(someDate, format) {
    return someDate ? moment(someDate).tz("UTC").format(format) : null;
  }

  /**
   * It turns out that if parseInt is not passed a second argument, it'll try to "guess" what radix it is. This can be very
   * dangerous. Always use this method instead of the regular parseInt.
   * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/parseInt
   *
   * @param value Some string or number to parse.
   * @param radix Base for the number to convert.
   */
  static toInteger(value, radix = 10) {
    // Not using CommonUtils because CommonUtils should actually be a facade to this one.
    return parseInt(value, radix);
  }

  /**
   * Safely converts string containing a list of trimmed values into an array.
   * @param value {string} the value to be split
   * @param separator {string} the separator
   * @return {string[]}
   */
  static toSplitList(value, separator = ",") {
    return (
      (value &&
        value
          .toString()
          .split(separator)
          .map((token) => token.trim())) ||
      []
    );
  }
}

module.exports = {
  Converters,
};
