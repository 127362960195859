"use strict";

import React from "react";

import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactDOMServer from "react-dom/server";
import { Trans } from "react-i18next";

import BaseReactComponent from "@client/base_react_component";
import * as I18NWrapper from "@client/i18n/i18n_wrapper";
import * as UIUtils from "@client/ui_utils";

// i18next-extract-mark-ns-start widgets
class PasswordRequirementsPanel extends BaseReactComponent {
  constructor(props) {
    super(props);
  }

  /*
  When you have a popup component (ex.widgets/password_requirements_panel.jsx)
  you must call popover function for it to show in top of the UI likewise
   */
  componentDidMount() {
    super.componentDidMount();
    $("[data-toggle='popover']").popover({
      sanitizeFn: UIUtils.sanitizePopoverData,
    });
    $("[data-toggle='validator']").validator("update");
  }

  render() {
    const { t } = this.props;

    return (
      <p
        className={
          "text-center" +
          (this.props.className ? " " + this.props.className : " passwordRequirements")
        }
        id="passwordRequirements"
      >
        <a
          id="passwordRequirementsLink"
          href="javascript:void(0);"
          data-toggle="popover"
          data-container="body"
          data-html="true"
          data-content={ReactDOMServer.renderToStaticMarkup(
            <div>
              <Trans t={t}>
                Passwords must be at least <b>12</b> characters long and contain all of the
                following:
              </Trans>
              <ul>
                <li>{t("1 upper case character")}</li>
                <li>{t("1 lower case character")}</li>
                <li>{t("1 number")}</li>
              </ul>
              {t(
                "Passwords may not contain your name and cannot be one of the last 8 passwords used.",
              )}
            </div>,
          )}
        >
          {t("Password Requirements")}
          <FontAwesomeIcon icon={faInfoCircle} />
        </a>
      </p>
    );
  }
}

export default I18NWrapper.wrap(PasswordRequirementsPanel, "widgets");
// i18next-extract-mark-ns-stop widgets
