"use strict";

import { LOG_GROUP, Log } from "../../../server/common/logger/common_log";

const Logger = Log.group(LOG_GROUP.Framework, "Cache");

/**
 * A simple lightweight container to maintain data across lifecycle of the page.
 * It only persists data in a single page request
 * It useful to avoid expensive call to TypeaheadCache (sessionStorage)
 */
export default class MemoryCache {
  isDisabled = false;

  constructor() {
    this.clear();
  }

  get(cacheKey) {
    if (this.isDisabled) {
      return null;
    }

    return this._internalCache[cacheKey];
  }

  set(cacheKey, value) {
    this._internalCache[cacheKey] = value;
  }

  clear() {
    this._internalCache = {};
  }

  /**
   * Get cache object
   * @returns {MemoryCache}
   */
  static getInstance() {
    const instance = new MemoryCache();
    MemoryCache.getAllInstances().push(instance);
    return instance;
  }

  /**
   * Get a cache object that can be reused across components using the name
   * @param name
   * @returns {*|MemoryCache}
   */
  static getNamedInstance(name) {
    if (!MemoryCache._namedInstances) {
      MemoryCache._namedInstances = {};
    }

    const namedInstance = MemoryCache._namedInstances[name];
    if (namedInstance) {
      return namedInstance;
    }

    Logger.debug("MemoryCache :: create new instance named " + name);
    const instance = MemoryCache.getInstance();
    MemoryCache._namedInstances[name] = instance;

    instance.isDisabled = false;
    return instance;
  }

  static clearAllInstances() {
    const instances = MemoryCache.getAllInstances();
    if (instances.length === 0) {
      return;
    }

    Logger.debug(`MemoryCache :: Clearing ${instances.length} instances`);

    for (let instance of instances) {
      instance.clear();
    }
  }

  static getAllInstances() {
    if (!MemoryCache._allInstances) {
      MemoryCache._allInstances = [];
    }

    return MemoryCache._allInstances;
  }
}
