"use strict";

const { Log, LOG_GROUP } = require("../../server/common/logger/common_log");
const Logger = Log.group(LOG_GROUP.Fragment, "RetryWrapper");

module.exports.RETRY_WAIT = [2 * 1000, 5 * 1000, 10 * 1000];

/**
 * Throw this to retry this method after a given wait time.
 *
 * @param message {string} The message that will be shown if the number of retries is exceeded.
 */
module.exports.RetryPleaseError = class extends Error {
  constructor(message) {
    super(message);
  }
};

/**
 * This class helps retry a function. It's useful when calling external services that are sometimes flaky (ie. Cognito, S3).
 */
module.exports.RetryWrapper = class {
  /**
   * @param someFuncParam {function} The function to run.
   * @param [beforeRetryFuncParam] {function} An optional function to run when a retry has been triggered. It will be
   * passed the zero-based retry count and the wait time. This can be used to show an error message to the user to let
   * them know that a retry is in progress.
   * @param [afterTooManyErrorsFuncParam] {function} An optional function that will be called once the number of retries
   * has been exceeded. It will be passed the most recent RetryPleaseError that was thrown.
   */
  constructor(someFuncParam, beforeRetryFuncParam, afterTooManyErrorsFuncParam = Logger.error) {
    this.someFunc = someFuncParam;
    this.beforeRetryFunc = beforeRetryFuncParam;
    this.afterTooManyErrorsFunc = afterTooManyErrorsFuncParam;
  }

  /**
   * Wait for a number of milliseconds.
   *
   * @param waitInMS How long to wait for.
   * @return {Promise<unknown>}
   */
  static wait(waitInMS) {
    return new Promise((resolve) => setTimeout(resolve, waitInMS));
  }

  /**
   * This will call someFunc and wait for it to complete its promise that is returned. If someFunc throws a RetryPlease
   * error, then after waiting, someFunc will be run again.
   *
   * @param retryAttempts {integer} The number of times to retry it before giving up.
   * @param retryWait {[]} An optional array of length retryAttempts that describes how long to wait if there's a failure.
   */
  async retryFunction(retryAttempts = 3, retryWait = exports.RETRY_WAIT) {
    let retryCount = 0;
    let methodPassed = false;
    let lastError;
    do {
      try {
        await this.someFunc();
        methodPassed = true;
      } catch (error) {
        if (error instanceof exports.RetryPleaseError) {
          const waitInMS = retryWait[retryCount];
          Logger.info(() => `Caught RetryPleaseError. Waiting for ${waitInMS}...`);
          this.beforeRetryFunc && (await this.beforeRetryFunc(retryCount, waitInMS));
          await exports.RetryWrapper.wait(waitInMS);
          lastError = error;
          retryCount++;
        } else {
          throw error; // Not for us
        }
      }
    } while (!methodPassed && retryCount < retryAttempts);

    if (!methodPassed) {
      this.afterTooManyErrorsFunc && this.afterTooManyErrorsFunc(lastError);
    }
  }
};
