import React from "react";

import { Button } from "@qbdvision-inc/component-library";

import { STEPS } from "@client/users/user_login";
import { QBDLink } from "@client/utils/withRouter";

/**
 * The form used when prompting the user for their username and/or password.
 */
export function UsernamePasswordForm({
  t,
  username,
  password,
  step,
  onInputChange,
  onCheckValidEmail,
  onUserLogin,
  passwordInputRef,
  shouldShowCreateCompanyButton,
}) {
  const showPasswordField = step === STEPS.ENTER_PASSWORD;
  return (
    <>
      <form data-toggle="validator" role="form" id="loginForm" onSubmit={onCheckValidEmail}>
        <div className="form-group">
          <input
            type="text"
            value={username}
            className="form-control"
            placeholder={t("Email / Username")}
            name="username"
            id="usernameInput"
            data-required-error={t("An email or username is required")}
            autoComplete="username"
            required={true}
            disabled={showPasswordField}
            onChange={onInputChange}
            autoFocus={step === STEPS.ENTER_USERNAME}
          />
          <div className="help-block with-errors" />
        </div>
        <>
          {/*
                    For password helpers just as LastPass to work fine,
                    the password field must be present in the DOM, but hidden.
                    */}
          <div className={`form-group ${showPasswordField ? "" : "d-none"}`}>
            <input
              type="password"
              value={password}
              placeholder={t("Password")}
              className="form-control"
              name="password"
              id="passwordInput"
              autoComplete="current-password"
              data-minlength={8}
              required={showPasswordField}
              onChange={onInputChange}
              ref={passwordInputRef}
            />
            <div className="help-block with-errors" />
          </div>
          <div className={`form-group ${showPasswordField ? "" : "d-none"}`}>
            <QBDLink id="forgotPasswordLink" to="/users/forgotPassword.html">
              {t("Forgot Password")}
            </QBDLink>
          </div>
        </>
        <br />
        {step === STEPS.ENTER_USERNAME ? (
          <div className="form-group">
            <Button id={"nextButton"} label={t("Next")} isSubmit isFullWidth />
          </div>
        ) : (
          <div className="form-group">
            <Button
              onClick={onUserLogin}
              id="loginButton"
              label={t("Login")}
              isSubmit
              isFullWidth
            />
          </div>
        )}
      </form>
      {shouldShowCreateCompanyButton && (
        <div id="createCompanyButton" className="form-group">
          <p className="text-center loginPageSeparator-text">{t("or")}</p>
          <p className="text-center loginPageLinks-text">
            <QBDLink to="/users/createCompany.html">{t("Create Company")}</QBDLink>
          </p>
        </div>
      )}
    </>
  );
}
